
import { defineComponent } from 'vue'
import TmTemplate from '@/components/shared/TmTemplate.vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import CampaignsDraftDropdown from '@/components/pages/campaigns/draft/CampaignsDraftDropdown.vue'
import { formatNumber } from '@/services/utils'

export default defineComponent({
  components: {
    TmTemplate,
    TmTable,
    CampaignsDraftDropdown,
  },
  setup() {
    return {
      formatNumber,
    }
  },
})
