
import { defineComponent } from 'vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmTableActionButton from '@/components/shared/table/TmTableActionButton.vue'

export default defineComponent({
  name: 'AllCategoriesDropdown',
  components: {
    TmDropdown,
    TmDropdownItem,
    TmTableActionButton,
  },
})
