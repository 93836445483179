import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-center blue-on-hover pointer font-weight-medium" }
const _hoisted_2 = { class: "d-flex align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_template = _resolveComponent("tm-template")!
  const _component_campaigns_draft_dropdown = _resolveComponent("campaigns-draft-dropdown")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, { "align-top": "" }, {
    "body-cell-name-slot": _withCtx(({ row }) => [
      _createElementVNode("span", _hoisted_1, [
        _createVNode(_component_tm_icon, {
          name: "edit",
          class: "distinct--text mr-2"
        }),
        _createElementVNode("span", null, _toDisplayString(row.name), 1)
      ])
    ]),
    "body-cell-recipients-slot": _withCtx(({ row }) => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_tm_icon, {
          name: "tmi-people",
          size: "xLarge",
          class: "primary--text mr-2"
        }),
        _createElementVNode("span", null, _toDisplayString(_ctx.formatNumber(row.recipients)), 1)
      ])
    ]),
    "body-cell-message-slot": _withCtx(({ row }) => [
      _createVNode(_component_tm_template, {
        content: row.content
      }, null, 8, ["content"])
    ]),
    "body-cell-actions": _withCtx(({ row }) => [
      _createVNode(_component_campaigns_draft_dropdown, { item: row }, null, 8, ["item"])
    ]),
    _: 1
  }))
}